@charset "utf-8";
// common
section {
  position: relative;
  margin-bottom: 120px;
}
section:last-child {
  margin-bottom: 195px;
}
.mark {
  position: absolute;
  left: 0;
  top: 0;
  border-top: 1px solid #000;
  writing-mode: vertical-rl;
}
.mark p {
  font-weight: bold;
  margin: 20px 0 0 20px;
}
h3 {
  font-weight: bold;
  font-size: 4.0rem;
  line-height: 1.25;
}
h3 span {
  font-size: 1.6rem;
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
.h3-caption {
  margin: 30px 0 50px;
}
.h3-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
}
.h3-img-text {
  margin-right: 35px;
}
.h3-img-text span {
  margin-bottom: 0;
}
.h3-img-logo img {
  width: 50%;
}
h4 {
  font-weight: bold;
  font-size: 3.0rem;
  line-height: 1.25;
  margin-bottom: 30px;
}
h4 span {
  font-size: 1.6rem;
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
}
.contact-button {
  background-color: #646464;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.contact-button a {
  color: #fff;
  text-align: center;
  display: block;
  position: relative;
  padding: 55px 0;
  font-size: 3.0rem;
  font-weight: bold;
  line-height: 1;
}
.contact-button a::after {
  content: "";
  position: absolute;
  top: 43%;
  right: 4%;
  display: block;
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  transition: right 500ms ease;
}
.contact-button:hover a::after {
  right: 2%;
}
.hakoniwa-ptn-man {
  width: 8%;
  position: absolute;
  bottom: -61%;
  left: 3%;
  transition: 0.3s;
}
.hakoniwa-ptn-woman {
  position: absolute;
  width: 5.5%;
  bottom: -84%;
  left: 11%;
  transition: 0.3s;
}
.animation01:hover .hakoniwa-ptn-man,
.animation03:hover .hakoniwa-ptn-man {
  bottom: 0%;
}
.animation02:hover .hakoniwa-ptn-woman,
.animation03:hover .hakoniwa-ptn-woman {
  bottom: -10%;
}

.kv {
  margin-bottom: 120px;
  background: url("/_assets/images/hakoniwa_mv.jpg") left top repeat-x;
	background-size: auto 100%;
  width: 100%;
  height: 560px;
  position: relative;
	animation: bg-slider 160s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kv img {
  width: 72.8%;
}
@keyframes bg-slider {
	from { background-position: 0 0; }
    to { background-position: -5115px 0; }
}
// about
.about-wrapper {
  max-width: 895px;
  margin: 0 auto;
}
.about-contents {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
}
.about-contents-text {
  width: 31%;
}
.about-contents-text p {
  margin-bottom: 30px;
}
.about-contents-img {
  width: 64%;
}
.about-contents-img img {
  border-radius: 20px;
}
// service
.service-wrapper {
  max-width: 895px;
  margin: 0 auto;
}
.service-contents {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  margin-bottom: 40px;
}
.service-contents-text--right {
  width: calc(100% + 60px);
  margin-left: 40px;
}
.service-contents-img--right {
  width: calc(100% + 185px);
  margin: 0 -185px 0 0;
}
.service-contents-img--right img {
  border-radius: 20px 0 0 20px;
}
.service-contents-text--left {
  width: calc(100% + 60px);
  margin-right: 40px;
}
.service-contents-img--left {
  width: calc(100% + 185px);
  margin: 0 0 0 -185px;
}
.service-contents-img--left img {
  border-radius: 0 20px 20px 0;
}

.service-contents-number {
  margin-bottom: 30px;
}
.service-contents-number01 {
  width: 7%;
}
.service-contents-number02 {
  width: 11%;
}
.service-contents-number03 {
  width: 11%;
}
.service-contents-number04 {
  width: 11%;
}
.service-human01 {
  position: absolute;
  top: 75px;
  left: -104px;
  width: 8.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-human02 {
  position: absolute;
  top: 50px;
  right: -110px;
  width: 10.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-human03 {
  position: absolute;
  top: 0px;
  left: 305px;
  width: 10.7%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-human04 {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 13.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-contact {
  position: relative;
}
.service-human05 {
  position: absolute;
  top: 0px;
  right: 90px;
  width: 11.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-contact-text {
  margin: 30px 0 40px;
}
// company
.company-wrapper {
  max-width: 895px;
  margin: 0 auto;
}
.company-table {
  width: 100%;
  border-top: 1px solid #000;
}
.company-table th {
  text-align: left;
  border-bottom: 1px solid #000;
  padding: 15px 0;
  width: 19%;
  vertical-align: top;
  font-weight: normal;
}
.company-table td {
  text-align: left;
  border-bottom: 1px solid #000;
  padding: 15px 0;
  width: 81%;
}
.company-table td span {
  display: block;
  font-weight: bold;
}
// contact
.contact-wrapper {
  max-width: 895px;
  margin: 0 auto;
}


@keyframes pikopiko {
  0% {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(-5deg);
  }
}


@media screen and (max-width:768px) { 
  // common
section {
  position: relative;
  margin-bottom: 120px;
}
section:last-child {
  margin-bottom: 195px;
}
.mark {
  position: absolute;
  left: 0;
  top: 0;
  border-top: 1px solid #000;
  writing-mode: vertical-rl;
}
.mark p {
  font-weight: bold;
  margin: 20px 0 0 20px;
}
h3 {
  font-weight: bold;
  font-size: 4.0rem;
  line-height: 1.25;
}
h3 span {
  font-size: 1.6rem;
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}
.h3-caption {
  margin: 30px 0 50px;
}
.h3-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 35px;
}
.h3-img-text {
  margin-right: 35px;
}
.h3-img-text span {
  margin-bottom: 0;
}
.h3-img-logo img {
  width: 50%;
}
h4 {
  font-weight: bold;
  font-size: 3.0rem;
  line-height: 1.25;
  margin-bottom: 30px;
}
h4 span {
  font-size: 1.6rem;
  display: block;
  font-weight: bold;
  margin-bottom: 20px;
}
.contact-button {
  background-color: #646464;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.contact-button a {
  color: #fff;
  text-align: center;
  display: block;
  position: relative;
  padding: 55px 0;
  font-size: 3.0rem;
  font-weight: bold;
  line-height: 1;
}
.contact-button a::after {
  content: "";
  position: absolute;
  top: 43%;
  right: 4%;
  display: block;
  width: 12px;
  height: 12px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
}
.hakoniwa-ptn-man {
  width: 8%;
  position: absolute;
  bottom: -61%;
  left: 3%;
  transition: 0.3s;
}
.hakoniwa-ptn-woman {
  position: absolute;
  width: 5.5%;
  bottom: -84%;
  left: 11%;
  transition: 0.3s;
}
.animation01:hover .hakoniwa-ptn-man,
.animation03:hover .hakoniwa-ptn-man {
  bottom: 0%;
}
.animation02:hover .hakoniwa-ptn-woman,
.animation03:hover .hakoniwa-ptn-woman {
  bottom: -10%;
}

.kv {
  margin-bottom: 120px;
  background: url("/_assets/images/hakoniwa_mv.jpg") left top repeat-x;
	background-size: auto 100%;
  width: 100%;
  height: 560px;
  position: relative;
	animation: bg-slider 160s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kv img {
  width: 72.8%;
}
@keyframes bg-slider {
	from { background-position: 0 0; }
    to { background-position: -5334px 0; }
}
// about
.about-wrapper {
  max-width: 80%;
  margin: 0 auto;
}
.about-contents {
  display: block;
  margin-top: 30px;
}
.about-contents-text {
  width: 100%;
}
.about-contents-text p {
  margin-bottom: 30px;
}
.about-contents-img {
  width: 100%;
}
.about-contents-img img {
  border-radius: 20px;
}
// service
.service-wrapper {
  max-width: 80%;
  margin: 0 auto;
}
.service-contents {
  display: block;
  position: relative;
  margin-bottom: 40px;
}
.service-contents-text--right {
  width: 100%;
  margin-left: 40px;
}
.service-contents-img--right {
  width: 100%;
  margin: 0;
}
.service-contents-img--right img {
  border-radius: 20px 0 0 20px;
}
.service-contents-text--left {
  width: 100%;
  margin-right: 40px;
}
.service-contents-img--left {
  width: 100%;
  margin: 0;
}
.service-contents-img--left img {
  border-radius: 0 20px 20px 0;
}

.service-contents-number {
  margin-bottom: 30px;
}
.service-contents-number01 {
  width: 7%;
}
.service-contents-number02 {
  width: 11%;
}
.service-contents-number03 {
  width: 11%;
}
.service-contents-number04 {
  width: 11%;
}
.service-human01 {
  position: absolute;
  top: 75px;
  left: -104px;
  width: 8.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-human02 {
  position: absolute;
  top: 50px;
  right: 0px;
  width: 10.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-human03 {
  position: absolute;
  top: 0px;
  left: 305px;
  width: 10.7%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-human04 {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 13.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-contact {
  position: relative;
}
.service-human05 {
  position: absolute;
  top: 0px;
  right: 90px;
  width: 11.6%;
  animation: pikopiko 1.5s steps(2, start) infinite;
}
.service-contact-text {
  margin: 30px 0 40px;
}
// company
.company-wrapper {
  max-width: 80%;
  margin: 0 auto;
}
.company-table {
  width: 100%;
  border-top: 1px solid #000;
}
.company-table th {
  text-align: left;
  border-bottom: 1px solid #000;
  padding: 15px 0;
  width: 19%;
  vertical-align: top;
  font-weight: normal;
}
.company-table td {
  text-align: left;
  border-bottom: 1px solid #000;
  padding: 15px 0;
  width: 81%;
}
.company-table td span {
  display: block;
  font-weight: bold;
}
// contact
.contact-wrapper {
  max-width: 80%;
  margin: 0 auto;
}
}
